.image-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .image-gallery img {
    width: 20%;
    height: auto;
    margin-bottom: 20px;
    cursor: pointer;
  }
  
  .image-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    /* margin-top: 60px; */
  }
  
  .image-popup-content {
    position: relative;
    width: 80%;
    max-width: 1200px;
    background: #ffffff3b;
    /* padding: 20px; */
    text-align: center;
    height:500px;
  }
  
  .image-popup-content img {
    max-width: 70%;
    max-height: 80vh;
    margin-bottom: 10px;
    height: 90%;
    width: 90%;
  }
  
  .image-popup-content p {
    font-size: 16px;
    margin-bottom: 20px;
    color: #fff;
  }
  
  .image-popup-nav {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .image-popup-nav button {
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    margin: 0 10px;
    cursor: pointer;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
  }
  
  /* For screens smaller than 768px */
@media screen and (max-width: 360px) {
  .image-popup-content {
    width: 70%;
    max-width: 500px;
    margin-right:150px;
    height: 400px;
    padding:20px;
  }
  
  .image-popup-content img {
    max-width: 100%;
    max-height: 60vh;
  }
}

/* For screens larger than 768px */
@media screen and (min-width: 768px) {
  .image-popup-content {
    width: 80%;
    max-width: 1200px;
  }
  
  .image-popup-content img {
    max-width: 70%;
    max-height: 80vh;
  }
}
